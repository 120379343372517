body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .custom-scroll::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
} */

.ant-input-disabled {
  color: rgba(0, 0, 0, 0.90) !important;
}

.ant-select-disabled .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.90) !important;
}

.ant-checkbox-wrapper-disabled{
  color: rgba(0, 0, 0, 0.90) !important;
}

.ant-checkbox-disabled + span{
  color: rgba(0, 0, 0, 0.90) !important;
}

input[type="checkbox"]:disabled{
  color: rgba(0, 0, 0, 0.90) !important;
}

.custom-row-error {
  height: 10px;
  font-size: 12px;
  padding: 0px;
  background-color: rgba(255, 0, 0, 0.2);
}

.custom-row-tarifa {
  height: 10px;
  padding: 0px;
}

.custom-row{
  font-size: 12px;
  max-height: 10px;
  padding: 0px;
}

.custom-column{
  background-color: rgba(0,0,0,0.15);
}

.inicio {
  width: 100%; /* Ajusta el ancho del contenedor según sea necesario */
  height: 100%; /* Ajusta la altura del contenedor según sea necesario */
  background-image: url('../public/home-background.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
}